import React from 'react'
import horizontalDiagram from '../assets/images/process_diagram_hor.svg'
import verticalDiagram from '../assets/images/process_diagram_vert.svg'
import NavBar from '../components/common/NavBar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCertificate, faChartArea, faComments, faLanguage, faTh, faUserAlt, } from '@fortawesome/pro-light-svg-icons'
import Layout from '../layouts'
import { graphql } from "gatsby"

const AgileLifecycleManagement = ({ data, location }) => (
  <Layout>
    <NavBar phoneNumber={data.agile_info.contact.phone} email={data.agile_info.contact.email} location={location} />
    <section id="process" className="bg-dark">
      <div className="container text-center">
        <div className="row">
          <div className="col-lg-8 offset-lg-2 col-md-12 offset-md-0">
            <h1 className="seo-h3 text-uppercase">Agile PLM</h1>
            <p className="seo-h1 text-lowercase ">
              <span className="text-primary">This is how we work</span>
            </p>
            <div className="lead">
              <h1 className="seo-strong">Agile Project Lifecycle Management</h1>{' '}
              is the optimal method for process management during software
              development. Combined with&nbsp;
              <h1 className="seo-strong">SCRUM methodologies</h1>, that means
              you’re a part of the process: it’s easier to keep an eye on the
              project and evaluate output.
            </div>
          </div>
        </div>
        <img
          src={horizontalDiagram}
          alt="SCRUM Process Diagram"
          className="process-diagram img-fluid d-none d-md-block"
        />
        <img
          src={verticalDiagram}
          alt="SCRUM Process Diagram"
          className="process-diagram img-fluid d-block d-md-none"
        />
      </div>
      <div className="container">
        <hr />
        <div className="row">
          <div className="col-xs-12 offset-xs-0 col-sm-8 offset-sm-2">
            <div
              className="text-center text-primary"
              style={{ fontSize: '2em', margin: '3rem 0' }}
            >
              The <h2 className="seo-strong">Agile methodology</h2> encompasses
              everything that needs to be done on each step of a project
            </div>
          </div>
        </div>
        <hr className="spacer" />
        <div className="row">
          <div className="col-sm-4 mb-2">
            <h3>SCRUM methodology</h3>
            <p>
              By working with us you get all the advantages of{' '}
              <strong>Agile SCRUM management</strong>. This model allows the{' '}
              <em>Product Owner</em> (PO) to work in a process-driven framework
              with the <em>Scrum Master</em> (PM) and development team members
              (developers, programmers, testers, designers). The PO has constant
              access every step of the way and can arrange changes at certain
              points to ensure that the project is going in the right direction.
            </p>
          </div>
          <div className="col-sm-4 mb-2">
            <h3>Priorities</h3>
            <p>
              The main focus of the SCRUM process is to ship software that
              responds to needs of the client’s target group. By constantly
              improving our workflow, we made sure that the time required for
              testing and documenting is as short as possible. Furthermore, bugs
              are fixed right away so the PO can adjust the budget according to
              the project's stage.
            </p>
          </div>
          <div className="col-sm-4 mb-2">
            <h3>Timing</h3>
            <p>
              SCRUM means working in iterations called <em>sprints</em>. At the
              end of every sprint, the Scrum Master meets with his team to sum
              up any completed work and plan out upcoming tasks. After every
              sprint the team delivers a fully functional and updated product
              version.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="tools" className="section">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 offset-xs-0 col-sm-10 offset-sm-1 col-md-8 offset-md-2">
            <h2 className="text-center">Project management</h2>
            <p className="lead text-center my-2">
              We have adopted remote-friendly project management practices, so
              we can effectively work with foreign clients while staying in our
              office in Wroclaw, Poland.
            </p>
          </div>
        </div>
        <br />
        <div className="row mt-5">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <div className="media feature">
              <div className="media-left">
                <FontAwesomeIcon
                  size='2x'
                  style={{ margin: '0 0.8rem' }}
                  icon={faLanguage}
                  className="text-primary"
                />
              </div>
              <div className="media-body">
                <h3>Multilingual staff</h3>
                <p>We speak English, German, Spanish and Polish</p>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <div className="media feature">
              <div className="media-left">
                <FontAwesomeIcon
                  size='2x'
                  style={{ margin: '0 0.8rem' }}
                  icon={faUserAlt}
                  className="text-primary"
                />
              </div>
              <div className="media-body">
                <h3>Dedicated account manager</h3>
                <p>To take care of all your needs</p>
              </div>
            </div>
          </div>
          <div className="clearfix d-none d-sm-block d-md-none" />
          <div className="col-xs-12 col-sm-6 col-md-4">
            <div className="media feature">
              <div className="media-left">
                <FontAwesomeIcon
                  size='2x'
                  style={{ margin: '0 0.8rem' }}
                  icon={faCertificate}
                  className="text-primary"
                />
              </div>
              <div className="media-body">
                <h3>Certified experts</h3>
                <p>SCRUM masters, developers</p>
              </div>
            </div>
          </div>
          <div className="clearfix d-none d-md-block" />
          <div className="col-xs-12 col-sm-6 col-md-4">
            <div className="media feature">
              <div className="media-left">
                <FontAwesomeIcon
                  size='2x'
                  style={{ margin: '0 0.8rem' }}
                  icon={faChartArea}
                  className="text-primary"
                />
              </div>
              <div className="media-body">
                <h3>Reporting</h3>
                <p>You are always up-to-date</p>
              </div>
            </div>
          </div>
          <div className="clearfix d-none d-sm-block d-md-none" />
          <div className="col-xs-12 col-sm-6 col-md-4">
            <div className="media feature">
              <div className="media-left">
                <FontAwesomeIcon
                  size='2x'
                  style={{ margin: '0 0.8rem' }}
                  icon={faComments}
                  className="text-primary"
                />
              </div>
              <div className="media-body">
                <h3>Remote-friendly communication</h3>
                <p>Slack, e-mail, phone, skype, livechat</p>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <div className="media feature">
              <div className="media-left">
                <FontAwesomeIcon
                  size='2x'
                  style={{ margin: '0 0.8rem' }}
                  icon={faTh}
                  className="text-primary"
                />
              </div>
              <div className="media-body">
                <h3>SCRUM</h3>
                <p>Agile-oriented workflow</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default AgileLifecycleManagement

export const query = graphql`
  query agileQuery {
    agile_info: dataJson {
      ...Contact
    }
  }
`